<template>
	<div class="grid">
		<div class="col-12">
			<Card>
                <template #title>
                    {{title}}
                </template>
                <template #content>
					<!-- Toolbar -->
					<Toolbar class="mb-4">
						<template #start>
							
						</template>
					</Toolbar>

					<!-- Data Table -->
					<DataTable class="p-datatable-gridlines p-datatable-sm" filterDisplay="menu" :rowHover="true" :lazy="true" :paginator="true" 
						paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport" 
						currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" responsiveLayout="scroll"
						v-model:filters="dataTable.params.filters"
						v-model:selection="dataTable.selectedData" dataKey="id"
						:rowsPerPageOptions="dataTable.rows_option"
						:value="dataTable.data"
						:rows="dataTable.params.rows" 
						:totalRecords="dataTable.totalRecords" 
						:loading="dataTable.loading" 
						@page="loadData($event)" @sort="loadData($event)">
						<template #header>
							<div class="flex justify-content-between flex-column sm:flex-row">
								<div class="p-fluid">
									<MultiSelect v-model="dataTable.selectedColumns" :options="dataTable.columns"  optionValue="field" optionLabel="header" placeholder="Select Columns" display="chip" style="width: 20em"/>
								</div>
								<span class="p-input-icon-left mb-2">
									<i class="pi pi-search" />
									<InputText v-model="dataTable.params.filters['global'].value" @keydown.enter="loadData()" :placeholder="dataTable.global_placeholder" v-tooltip.top.focus="'Press Enter'" style="width: 100%"/>
								</span>
							</div>
						</template>
						<template #empty>
							<div class="p-text-center">No data found.</div>
						</template>
						<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
						<!-- Columns -->
						<Column v-for="(col, i) of dataTable.columns.filter(col => dataTable.selectedColumns.includes(col.field))" :field="col.field" :header="col.header" :sortable="col.sort" :showFilterMatchModes="false" :key="i">
							<template #body="{data}">
								<div v-if="col.default">{{ data[col.field] }}</div>
								<div v-if="col.field == 'kegiatan'">{{ data.kegiatan?.nama }}</div>
                                <div v-if="col.field == 'nomor'">No.{{data.kegiatan.absensi[0].no_urut}}/{{ data.nomor }}</div>
								<div v-if="col.field == 'action'">
									<Button v-if="$can('member-sertifikat')" @click="printSertifikat(data.id)" v-tooltip.top="'Print'" icon="pi pi-print" class="p-button-rounded p-button-text p-button-primary mr-2" />
								</div>
							</template>

							<template v-if="col.filter" #filter="{filterModel,filterCallback}">
								<InputText v-if="col.default" type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="column-filter" placeholder="Search" />
								<InputText v-if="col.field == 'nomor'" type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="column-filter" placeholder="Search" />
							</template>
						</Column>
						<!-- End Columns -->
					</DataTable>

					<small>Note : ...</small>

				</template>
			</Card>

		</div>

        <div id="print-area" class="hide"></div>
	</div>

</template>

<script>
export default {
	data() {
		return {
			title: 'Sertifikat',
			api: '/api/sertifikat-member',
			relations: {
				kegiatan: []
			},
			dataTable: {
				data: [],
				loading: false,
				global_placeholder: 'nomor',
				totalRecords: 0,
				rows_option: [10, 25, 50, 100],
				selectedData: [],
				columns: [
					{field: 'kegiatan', header: 'Kegiatan', sort: true, filter: false},
					{field: 'nomor', header: 'Nomor', sort: false, filter: true},
					{field: 'action', header: 'Action', sort: false, filter: false},
				],
				selectedColumns: ['kegiatan', 'nomor', 'action'],
				params: {
					filters: {
						global: {value: null},
						nomor: {value: null},
					},
					rows: 10, //per_page
					page: 0,
					sortField: null,
					sortOrder: null,
				},
			},
			dialog: {
				formData: false,
				deleteData: false,
			},
			formDataTitle: null,
		}
	},
	methods: {
		loadData(event = null){
			this.$Progress.start();
			this.dataTable.loading = true;
			(event) ? this.dataTable.params = event : null;
			this.$httpAuth.get(this.api, {
				params: {
					rows: this.dataTable.params.rows,
					sortField: this.dataTable.params.sortField,
					sortOrder: this.dataTable.params.sortOrder,
					filters: this.dataTable.params.filters,
					page: this.dataTable.params.page + 1,
				}
			})
			.then((response) => {
				this.$Progress.finish();
				this.dataTable.loading = false;
				this.dataTable.data = response.data.data;
				this.dataTable.totalRecords = response.data.total;
			})
			.catch((error) => {
				this.$Progress.fail();
				this.dataTable.loading = false;
                this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
            });
		},

        printSertifikat(id){
            this.$Progress.start();
			this.$httpAuth.get(`${this.api}/${id}`)
			.then((response) => {
				this.$Progress.finish();
				setTimeout(() => {
                    $("#print-area").html(response.data.template);

                    $("#print-area").html(function(index,html){
                        return html.replace(/{{nomor}}/g, `No.${response.data.kegiatan.absensi[0].no_urut}/${response.data.nomor}`);
                    });
                    $("#print-area").html(function(index,html){
                        return html.replace(/{{nama_kegiatan}}/g, response.data.kegiatan.nama);
                    });
                    $("#print-area :not(:has(*))").html((index,self) => {
                        return self.replace(/{{tanggal_kegiatan}}/g, this.$filter_date(response.data.kegiatan.tanggal));
                    });
                    $("#print-area :not(:has(*))").html((index,self) => {
                        return self.replace(/{{waktu_mulai_kegiatan}}/g, response.data.kegiatan.waktu_mulai.substr(0,5));
                    });
                    $("#print-area :not(:has(*))").html((index,self) => {
                        return self.replace(/{{waktu_selesai_kegiatan}}/g, response.data.kegiatan.waktu_selesai.substr(0,5));
                    });
                    $("#print-area").html(function(index,html){
                        return html.replace(/{{nama_peserta}}/g, response.data.kegiatan.absensi[0].member.nama);
                    });

                    $("#print-area :not(:has(*))").html((index,html) => {
                        return html.replace(/<style>/g, '<style media="print">');
                    });
                    $("#print-area :not(:has(*))").html((index,html) => {
                        return html.replace(/<!-- pagebreak -->/g, '<div class="page-break"></div>');
                    });

                }, 10);
                setTimeout(() => {
                    const cssText = `
                    * {
                        box-sizing: border-box;
                    }
                    .hide {
                        display: block;
                    }
                    .page-break {
                        page-break-after: always;
                    }
                    `;
                    const d = new this.$Printd();
                    d.print( document.getElementById('print-area'), [ cssText ] );
                }, 500)
			})
			.catch((error) => {
				this.$Progress.fail();
                this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
            });
        }
	},
	watch: {
		'dataTable.params.filters': function(){
			this.loadData();
		},
    },
	created(){
		this.$e.emit('updateTitle', this.title);
	},
	mounted() {
		this.loadData();
	},
}
</script>

<style>
.hide{
    display: none;
}
</style>